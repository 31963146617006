.siteContainer {
  min-height: 100vh;
  width: 95%;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding-top: 20px;
  color: black;
  padding-left: 5%;
  font-size: 20px;
}

.forms textarea {
  width: 80%;
  min-height: 250px;
  display: block;
}

.forms form {
  display: block;
}
