.postForm .radioCon,
.postForm input[type="text"],
.postForm input[type="number"],
.postForm input[type="file"],
.postForm input[type="date"],
.postForm select {
  width: 35%;
  min-width: 200px;
  padding: 8px 20px;
  margin: 8px 0;
  display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.admin-react-select {
  width: 35%;
}

.postForm .radioBtn {
  float: left;
}

.postForm button {
  width: 35%;
  min-width: 200px;
  background-color: #4caf50;
  color: white;
  padding-left: 20px;
  padding-top: 14px;
  padding-bottom: 14px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.postForm button:hover {
  background-color: #45a049;
}

.postForm button:disabled {
  background-color: #40826d;
  cursor: default;
}

.postForm-container {
  width: 95%;
  min-height: calc(100vh - 124px);
  border-radius: 5px;
  background-color: #f2f2f2;
  padding-top: 20px;
  color: black;
  padding-left: 5%;
  font-size: 20px;
}
