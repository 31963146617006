@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  margin: 0;
  padding: 0;
  background: white;
  position: relative;
  z-index: 2;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12);
}
nav a {
  color: black;
  text-decoration: none;
}
nav .menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
nav .logo {
  margin-left: 15px;
  transition: opacity ease 0.3s;
  cursor: pointer;
  align-self: center;
}

nav .logo:hover {
  opacity: 0.8;
}
nav .item {
  transition: background ease 0.3s;
  -webkit-transition: background ease 0.3s;
  padding: 19px 16px;
  font-weight: 500;
}

nav .item:hover {
  background: rgba(65, 74, 76, 0.5);
}

/* Mobile */

nav .menu { /* OLD - iOS 6-, Safari 3.1-6, BB7 */ /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
nav .menu .logo a {
  padding: 0;
  display: flex;
}

nav .toggle {
  order: 1;
  font-size: 20px;
}

nav .icon {
  display: flex;
  text-align: center;
  text-decoration: none;
  transition: background-color ease-out 0.3s;
  border: none;
  font-size: large;
  background-color: white;
  cursor: pointer;
  padding: 5px;
}

nav .icon:hover {
  background: rgba(65, 74, 76, 0.5);
}

nav .burger {
  cursor: pointer;
  display: block;
  height: 3.25rem;
  position: relative;
  width: 3.25rem;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

nav .burger span {
  background-color: black;
  border-radius: 5px;
  display: block;
  height: 2px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3px;
  position: relative;
  transform-origin: center;
  transition-duration: 0.1s;
  transition-property: opacity, transform;
  transition-timing-function: ease-out;
  width: 20px;
}

nav .burger.active span:first-child {
  transform: translateY(5px) rotate(45deg);
}
nav .burger.active span:nth-child(2) {
  opacity: 0;
}
nav .burger.active span:nth-child(3) {
  transform: translateY(-5px) rotate(-45deg);
}

nav .menu li .item.button {
  order: 2;
}
nav .menu .menu-item {
  order: 3;
  width: 100%;
  text-align: center;
  display: none;
  justify-content: center;
}
nav .menu .menu-item .item {
  width: 100%;
}
nav .menu .active.menu-item {
  display: flex;
}

/* Desktop menu */
@media all and (min-width: 600px) {
  nav {
    padding: 0 30px;
  }
  nav .menu {
    justify-content: flex-start;
    flex-wrap: nowrap;
    background: none;
  }
  nav .logo {
    margin-right: auto;
    order: 0;
  }

  nav .menu .menu-item {
    order: 1;
    position: relative;
    display: flex;
    width: auto;
  }
  nav .button {
    order: 2;
  }
  nav .toggle {
    display: none;
  }
}

.footer {
  background-image: url(/static/media/footer.57928b0c.jpg);
  height: 50px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  display: block;
  z-index: 2;
}

.home {
  height: 100vh;
  margin-bottom: -62px;
}
.bgimg-1,
.bgimg-2,
.bgimg-3 {
  position: relative;
  #background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bgimg-1 {
  position: relative;
  height: calc(100% - 62px);
}

.bgimg-2 {
  background-image: url(/static/media/corn-1.f17cc399.jpg);
  min-height: 400px;
}

.caption {
  position: absolute;
  display: flex;
  top: 40%;
  justify-content: center;
  width: 100%;
  text-align: center;
  z-index: 1;
  pointer-events: none;
}

.caption h1 {
    color: #fff;
    font-size: 6vw;
    font-weight: 500;
    text-shadow: 2px 2px #000;
}

.caption .border {
}

.background-solid {
  width: 100%;
  background-color: #ffffff;
}

.text-break {
  color: #333;
  background-color: white;
  text-align: justify;
  padding: 50px 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
}

.text-break h2 {
  #position: relative;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-size: 20px;
  #f: "Lato", sans-serif;
  color: #111;
}

/* Turn off parallax scrolling for tablets and phones */
@media only screen and (max-device-width: 1024px) {
  .bgimg-1,
  .bgimg-2,
  .bgimg-3 {
    background-attachment: scroll;
  }
  .caption {
  }
  .text-break {
    padding: 50px 25px;
  }
}

.loader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



.inputField {
  padding-top: 17px;
}

.input {
  box-sizing: border-box;
  outline: none;
  border: solid 2px #1189de;
  border-radius: 4px;
  color: #292929;
  width: 100%;
  padding: 12px;
  font-size: 14px;
  background: rgba(255, 255, 255, 1);
}

.submitButton {
  padding-top: 16px;
}

.btn {
  width: 100%;
  color: #565656;
  padding: 12px;
  font-size: 14px;
  font-weight: bold;
  border: solid 2px #1189de;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
}

.loginForm {
  box-sizing: border-box;
  position: absolute;
  top: 30%;
  width: 100%;
  max-width: 400px;
  padding-left: 20px;
  padding-right: 20px;
}

adminnav {
  margin: 0;
  padding: 0;
  background: black;
  position: relative;
  z-index: 2;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12);
}

adminnav .menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

adminnav .item {
  color: white;
  cursor: pointer;
  transition: background ease 0.3s;
  -webkit-transition: background ease 0.3s;
  padding: 19px 16px;
  font-weight: 500;
}

adminnav .item:first-child {
}

adminnav .item:last-child {
  position: absolute;
  right: 0;
}

adminnav .item:hover {
  background: grey;
}

adminnav a {
  text-decoration: none;
}

adminnav .menu { /* OLD - iOS 6-, Safari 3.1-6, BB7 */ /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
adminnav .menu li a {
  display: block;
  padding: 15px 5px;
}

.postForm .radioCon,
.postForm input[type="text"],
.postForm input[type="number"],
.postForm input[type="file"],
.postForm input[type="date"],
.postForm select {
  width: 35%;
  min-width: 200px;
  padding: 8px 20px;
  margin: 8px 0;
  display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.admin-react-select {
  width: 35%;
}

.postForm .radioBtn {
  float: left;
}

.postForm button {
  width: 35%;
  min-width: 200px;
  background-color: #4caf50;
  color: white;
  padding-left: 20px;
  padding-top: 14px;
  padding-bottom: 14px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.postForm button:hover {
  background-color: #45a049;
}

.postForm button:disabled {
  background-color: #40826d;
  cursor: default;
}

.postForm-container {
  width: 95%;
  min-height: calc(100vh - 124px);
  border-radius: 5px;
  background-color: #f2f2f2;
  padding-top: 20px;
  color: black;
  padding-left: 5%;
  font-size: 20px;
}

.postsContainer {
  min-height: calc(100vh - 124px);
  width: 100%;
  background-color: #f2f2f2;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.postsContainer .tableFlex {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 124px);
}

.postsContainer .tableFlex .topRow {
  width: 50%;
  height: 100%;
  flex-direction: column;
  display: flex;
}

.postsContainer table,
.postsContainer th,
.postsContainer td {
  border-style: none;
  border-collapse: collapse;
  text-align: center;
}

.postsContainer .tableContainer .tableRowOdd {
  background: #d4d4d4;
}

.postsContainer .half-table {
  max-height: 50%;
}
.postsContainer .posts-table {
  width: 50%;
}

.postsContainer .tableHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.postsContainer .tableHeading {
  display: block;
  margin: auto;
  align-self: center;
  font-weight: 700;
  font-size: large;
}

.postsContainer table {
  background: white;
  width: 100%;
}

.postsContainer .tableContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /*   overflow-y: scroll; */
}

.postsContainer .tableContent {
  width: 100%;
  overflow-y: scroll;
}

.fbtn {
  display: block;
  color: white;
  font-weight: 500;
  padding: 14px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  text-decoration: none;
}

.okay-btn {
  background-color: #4caf50;
}

.okay-btn:hover {
  background-color: #90ee90;
}

.danger-btn {
  background-color: #ff0000;
}

.danger-btn:hover {
  background-color: #ff5252;
}

.warning-btn {
  background-color: #ff9a00;
}

.warning-btn:hover {
  background-color: #ffc100;
}

.new-btn {
  float: right;
  width: auto;
  padding: 14px 5px;
}

@media screen and (max-width: 720px) {
  .postsContainer .tableFlex {
    height: 100%;
    flex-direction: column;
  }
  .postsContainer .tableContainer {
    max-height: 100%;
  }

  .postsContainer .tableFlex .topRow {
    width: 100%;
  }
  .postsContainer .posts-table {
    width: 100%;
  }
}

.siteContainer {
  min-height: 100vh;
  width: 95%;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding-top: 20px;
  color: black;
  padding-left: 5%;
  font-size: 20px;
}

.forms textarea {
  width: 80%;
  min-height: 250px;
  display: block;
}

.forms form {
  display: block;
}

.empty-container {
  max-width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.admin-container {
  height: calc(100vh - 124px);
}

.login-container {
  height: calc(100vh - 62px);
}

.contact {
  background-color: whitesmoke;
  min-height: calc(100vh - 62px);
  #padding-bottom: 75px;
  position: relative;
  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
}

.contact .heading-container {
  width: 100%;
  display: inline-block;
  text-align: left;
}

.contact .form {
  min-height: calc(100vh - 62px);
  padding: 25px;
  background-color: white;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 50%;
  color: black;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
}
.contact .form form {
  #border-style: solid;
  width: 100%;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.contact .contact-title {
  font-weight: 600;
  padding-bottom: 15px;
  font-size: 16px;
}

.contact .contact-subtitle {
  font-size: small;
  font-weight: 300;
  padding-bottom: 50px;
}

.contact .contact-links {
  font-weight: 200;
  font-size: small;
  #padding-left: 5px;
  padding-bottom: 30px;
}

.contact .form a {
  text-decoration: none;
  color: black;
  font-weight: 400;
  transition: color ease 0.2s;
}

.contact .form a:hover {
  color: grey;
}

.contact .form label {
  #margin-left: 5px;
  font-weight: 400;
  font-size: small;
}

.contact .form input[type="text"],
.contact .form textarea {
  width: 100%;
  min-height: 45px;
  padding: 8px 20px;
  margin: 8px 0;
  display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #fafafa;
  transition: background-color ease 0.2s;
}
.contact .form textarea {
  max-width: 100%;
  min-height: 100px;
}

.contact .form input[type="text"]:focus,
.contact .form textarea:focus {
  background-color: white;
}

.contact .form .contact-submitBtn {
  background-color: #171717;
  color: white;
  padding: 5px 20px 5px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  cursor: pointer;
  transition: background-color ease 0.2s;
  font-weight: 600;
}

.contact .form .contact-submitBtn:hover {
  background-color: #454545;
}

.contact .form .contact-submitBtn:disabled {
  background-color: #cdcdcd;
  cursor: default;
}

@media screen and (max-width: 820px) {
  .contact .form {
    width: calc(100% - 50px);
  }
}

.gallery {
  background-color: whitesmoke;
  min-height: 100vh;
  position: relative;
  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.load-container {
  background-color: whitesmoke;
  min-height: 100vh;
  position: relative;
  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.spinner {
  padding-top: 20%;
}

.divider {
  padding-top: 25px;
}

.grid-container {
  display: flex;
  justify-content: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
  margin-left: 50px;
  margin-right: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  transition: all ease 1s;
  align-items: center;
}

@media screen and (max-width: 820px) {
  .grid {
    max-width: 500px;
  }
}

.post {
  position: relative;
  width: 100%;
  border-style: none;
  transition: all ease 1s;
}

.post-unloaded {
  opacity: 0;
  transform: scale(0.5);
}

.post-loaded {
  transform: scale(1);
  opacity: 1;
}

.post .box {
  background: black;
  display: block;
}

.post .text-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  transition: transform ease 0.3s;
  background-color: rgba(65, 74, 76, 0);
}

.text-container h1 {
  margin: 0;
  font-size: xx-large;
  font-weight: 500;
  color: white;
  text-shadow: 2px 2px #000;
  opacity: 0;
  transition: opacity ease 0.3s;
}

.text-container .price {
  margin: 0;
}

.post .badge {
  /*position: absolute;
  top: 20%;
  margin-left: 5px;*/
  position: relative;
  text-shadow: 0px 0px #000;
  padding: 2px;
  border-radius: 5px;
  background-color: red;
  color: white;
  opacity: 0;
  font-weight: 600;
  transition: opacity ease 0.3s;
  margin-left: auto;
  margin-right: auto;
}

.text-container p {
  color: white;
  text-shadow: 1px 1px #000;
  opacity: 0;
  transition: opacity ease 0.3s;
}

.clicked img {
  opacity: 0.8;
}

.post img {
  position: relative;
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  transition: transform ease 0.3s;
  box-shadow: 3px 3px 15px rgba(10, 10, 10, 0.6);
}

.no-display {
  display: none;
}

.persistent-badge {
  position: absolute;
  text-align: center;
  font-weight: 600;
  z-index: 1;
  transform: rotate(45deg);
  top: 25px;
  right: -75px;
  width: 200px;
  background: #eb0000;
  color: white;
  transition: opacity ease 0.3s;
}

@media only screen and (min-device-width: 1024px) {
  .post:hover h1,
  .post:hover p,
/*   .post:hover .badge, */
  .post:hover .text-container {
    opacity: 1;
    z-index: 1;
  }

  .post:hover .text-container {
    background-color: rgba(65, 74, 76, 0.4);
  }

  .post:hover .text-container {
    transform: scale(1.05);
  }

  .post:hover .persistent-badge {
    /*     opacity: 0; */
  }
  .post:hover {
    cursor: pointer;
  }

  .post:hover img {
    transform: scale(1.05);
  }
}

.post-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: whitesmoke;
  min-height: calc(100vh - 62px);
  position: relative;
  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 0px;
}

.post-container .img-container .main-img-container {
  align-items: flex-start;
  max-height: auto;
  display: flex;
  justify-content: center;
}

.post-container .img-container .main-img-container img {
  max-height: 95vh;
  max-width: 100%;
  box-shadow: 4px 4px 20px rgba(10, 10, 10, 0.8);
}

.post-container .img-loading {
  transform: scale(0.8);
  opacity: 0;
}

.post-container .img-loaded {
  transform: scale(1);
  opacity: 1;
  transition: transform ease 1s;
  transition: opacity ease 1s;
}

.post-container .img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-container .img-container .arrow {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  min-width: 50px;
  transition: background ease 0.3s;
}

.post-container .img-container .arrow-hide {
  opacity: 0;
  min-width: 50px;
  margin: 20px 0px 20px 0px;
  cursor: default;
}

.post-container .img-container .arrow:hover {
  background: #d3d3d3;
}

.post-container .img-container .arrow img {
  transition: transform ease-in 0.1s;
}

.post-container .img-container .arrow:hover img {
  transform: scale(1.2);
}

.post-container .card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 100px;
  width: calc(100% - 100px);
  max-width: 350px;
  margin: 25px 0 25px 0;
  text-align: left;
  color: black;
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);

  /*  -moz-box-shadow: 3px 3px 15px rgba(10, 10, 10, 0.6);
  -moz-box-shadow: 3px 3px 15px rgba(10, 10, 10, 0.6);
  box-shadow: 3px 3px 15px rgba(10, 10, 10, 0.6);*/
}

.post-container .card .title-container {
  margin: 15px;
  font-size: 15px;
}

.post-container .card .card-details {
  margin-top: 5px;
  font-size: 12px;
}

.post-container .card p {
  margin: 0;
  font-weight: 300;
}
.post-container .card p.phone {
  font-weight: 400;
}
.post-container .title {
  /*padding-top: 25px;*/
  font-weight: 500;
  font-size: large;
  font-style: italic;
  margin: 0;
}

.post-container .loading {
  opacity: 0;
}
.post-container .loaded {
  transition: all ease 1s;
  opacity: 1;
}

.back {
  padding: 25px 0;
  align-self: flex-start;
  display: inline-block;
  width: auto;
  padding-left: 15px;
  font-size: medium;
  color: #565656;
}

.back:hover {
  text-decoration: underline;
  cursor: pointer;
}

.post-container .vendor a {
  color: black;
  text-decoration: underline;
  font-weight: 300;
  transition: color ease 0.2s;
}

.post-container .vendor a:hover {
  color: grey;
}

/*Margin by pixel:*/
@media screen and (max-width: 720px) {
  .post-container .img-container .main-img-container img {
    max-height: 70vh;
  }
  .post-container .card {
      margin-left: 50px;
      margin-right: 50px;
  }
}

.react-select {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  opacity: 0;
  transition: opacity ease 1s;
  z-index: 1;
}

.react-select-loaded {
  opacity: 1;
}

.page-navigation-container {
  position: relative;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
}

.nav-buttons-container {
  display: flex;
  float: left;
}

.pgnav-btn {
  padding: 10px;
  background-color: #89abe3ff;
  color: white;
  #border: 1px solid blue;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  margin: 0px 5px 0px 5px;
  opacity: 0;
  transition: opacity ease 1s;
}

.pgnav-btn-loaded {
  opacity: 1;
}

.pgnav-btn:hover {
  background-color: #84cdee;
}

.pgnav-btn-disabled {
  background-color: #cdcdcd;
  cursor: default;
}
.pgnav-btn-disabled:hover {
  background-color: #cdcdcd;
}

.pgnav-btn-active {
  background-color: #a7a7a7;
  cursor: default;
}

.pgnav-btn-active:hover {
  background-color: #a7a7a7;
}

@media screen and (max-width: 1024px) {
  .pgnav-btn-number {
    display: none;
  }
}

.bio-container {
  background-color: whitesmoke;
  min-height: calc(100vh - 62px);
  #padding-bottom: 75px;
  position: relative;
  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
}
.bio {
  background: white;
  padding: 25px;
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
}

.bio .block {
  position: relative;

  padding-left: 50px;
  padding-top: 10px;
}

.bio .heading {
  #position: absolute;
  #float: left;
  #width: 25%;
  height: 100%;
  #background-color: #8c8bac;
  #color: #ccccff;
  color: black;
  #text-align: center;
  font-size: x-large;
  font-weight: 600;
  /*   letter-spacing: -1.5px; */
  line-height: 25px;
}

.bio .heading h1 {
  #text-align: center;
}

.bio .text {
  #width: 75%;
  overflow: hidden;
  padding-top: 25px;
  padding-bottom: 25px;
  color: black;
  line-height: 12px;
}
.bio .text .element {
  padding-bottom: 5px;
  padding-top: 5px;
}

.bio .text h4 {
  font-size: medium;
  padding-left: 10px;
  font-weight: 600;
  line-height: 20px;
}

.bio .text p {
  margin-top: -15px;
  padding-left: 10px;
  font-size: small;
  font-weight: 300;
  line-height: 12px;
}

.bio .img-container {
  float: right;
  margin: 5px;
  /*   padding-right: 25px; */
  width: 33%;
}

.bio .img-container img {
  height: auto;
  width: 100%;
}

@media screen and (max-width: 820px) {
  .bio {
    padding: 0;
  }
  .bio .text {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-left: 0;
  }

  .bio .text h4 {
    padding-left: 0;
  }

  .bio .text p {
    padding-left: 0;
  }

  .bio {
    width: 100%;
  }
  .bio .img-container {
    float: none;
    margin: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 0 0 0;
    width: 250px;
  }
}

.NotFoundContainer {
  min-height: calc(100vh - 82px);
  width: 100%;
  background-color: #f2f2f2;
  padding-top: 20px;
  color: black;
  font-size: 20px;
}

body,
html,
#root,
.App {
  #padding-top: 10px; /* Add a top margin to avoid content overlay */
  height: 100%;
  font-family: "Poppins", sans-serif;
  color: #777;
  background-color: #2f4f4f;
  display: flex;
  flex-direction: column;
}

