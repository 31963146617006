.page-navigation-container {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
}

.nav-buttons-container {
  display: flex;
  float: left;
}

.pgnav-btn {
  padding: 10px;
  background-color: #89abe3ff;
  color: white;
  #border: 1px solid blue;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  margin: 0px 5px 0px 5px;
  opacity: 0;
  transition: opacity ease 1s;
}

.pgnav-btn-loaded {
  opacity: 1;
}

.pgnav-btn:hover {
  background-color: #84cdee;
}

.pgnav-btn-disabled {
  background-color: #cdcdcd;
  cursor: default;
}
.pgnav-btn-disabled:hover {
  background-color: #cdcdcd;
}

.pgnav-btn-active {
  background-color: #a7a7a7;
  cursor: default;
}

.pgnav-btn-active:hover {
  background-color: #a7a7a7;
}

@media screen and (max-width: 1024px) {
  .pgnav-btn-number {
    display: none;
  }
}
