.post-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: whitesmoke;
  min-height: calc(100vh - 62px);
  position: relative;
  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 0px;
}

.post-container .img-container .main-img-container {
  align-items: flex-start;
  max-height: auto;
  display: flex;
  justify-content: center;
}

.post-container .img-container .main-img-container img {
  max-height: 95vh;
  max-width: 100%;
  -moz-box-shadow: 4px 4px 20px rgba(10, 10, 10, 0.8);
  -webkit-box-shadow: 4px 4px 20px rgba(10, 10, 10, 0.8);
  box-shadow: 4px 4px 20px rgba(10, 10, 10, 0.8);
}

.post-container .img-loading {
  transform: scale(0.8);
  opacity: 0;
}

.post-container .img-loaded {
  transform: scale(1);
  opacity: 1;
  transition: transform ease 1s;
  transition: opacity ease 1s;
}

.post-container .img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-container .img-container .arrow {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  min-width: 50px;
  transition: background ease 0.3s;
}

.post-container .img-container .arrow-hide {
  opacity: 0;
  min-width: 50px;
  margin: 20px 0px 20px 0px;
  cursor: default;
}

.post-container .img-container .arrow:hover {
  background: #d3d3d3;
}

.post-container .img-container .arrow img {
  transition: transform ease-in 0.1s;
}

.post-container .img-container .arrow:hover img {
  transform: scale(1.2);
}

.post-container .card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 100px;
  width: calc(100% - 100px);
  max-width: 350px;
  margin: 25px 0 25px 0;
  text-align: left;
  color: black;
  background-color: white;
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12),
    0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.12),
    0 8px 8px rgba(0, 0, 0, 0.12), 0 16px 16px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);

  /*  -moz-box-shadow: 3px 3px 15px rgba(10, 10, 10, 0.6);
  -moz-box-shadow: 3px 3px 15px rgba(10, 10, 10, 0.6);
  box-shadow: 3px 3px 15px rgba(10, 10, 10, 0.6);*/
}

.post-container .card .title-container {
  margin: 15px;
  font-size: 15px;
}

.post-container .card .card-details {
  margin-top: 5px;
  font-size: 12px;
}

.post-container .card p {
  margin: 0;
  font-weight: 300;
}
.post-container .card p.phone {
  font-weight: 400;
}
.post-container .title {
  /*padding-top: 25px;*/
  font-weight: 500;
  font-size: large;
  font-style: italic;
  margin: 0;
}

.post-container .loading {
  opacity: 0;
}
.post-container .loaded {
  transition: all ease 1s;
  opacity: 1;
}

.back {
  padding: 25px 0;
  align-self: flex-start;
  display: inline-block;
  width: auto;
  padding-left: 15px;
  font-size: medium;
  color: #565656;
}

.back:hover {
  text-decoration: underline;
  cursor: pointer;
}

.post-container .vendor a {
  color: black;
  text-decoration: underline;
  font-weight: 300;
  transition: color ease 0.2s;
}

.post-container .vendor a:hover {
  color: grey;
}

/*Margin by pixel:*/
@media screen and (max-width: 720px) {
  .post-container .img-container .main-img-container img {
    max-height: 70vh;
  }
  .post-container .card {
      margin-left: 50px;
      margin-right: 50px;
  }
}
