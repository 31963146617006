nav {
  margin: 0;
  padding: 0;
  background: white;
  position: relative;
  z-index: 2;
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12),
    0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.12),
    0 8px 8px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12);
}
nav a {
  color: black;
  text-decoration: none;
}
nav .menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
nav .logo {
  margin-left: 15px;
  transition: opacity ease 0.3s;
  cursor: pointer;
  align-self: center;
}

nav .logo:hover {
  opacity: 0.8;
}
nav .item {
  transition: background ease 0.3s;
  -webkit-transition: background ease 0.3s;
  padding: 19px 16px;
  font-weight: 500;
}

nav .item:hover {
  background: rgba(65, 74, 76, 0.5);
}

/* Mobile */

nav .menu {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
nav .menu .logo a {
  padding: 0;
  display: flex;
}

nav .toggle {
  order: 1;
  font-size: 20px;
}

nav .icon {
  display: flex;
  text-align: center;
  text-decoration: none;
  transition: background-color ease-out 0.3s;
  border: none;
  font-size: large;
  background-color: white;
  cursor: pointer;
  padding: 5px;
}

nav .icon:hover {
  background: rgba(65, 74, 76, 0.5);
}

nav .burger {
  cursor: pointer;
  display: block;
  height: 3.25rem;
  position: relative;
  width: 3.25rem;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

nav .burger span {
  background-color: black;
  border-radius: 5px;
  display: block;
  height: 2px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3px;
  position: relative;
  transform-origin: center;
  transition-duration: 0.1s;
  transition-property: opacity, transform;
  transition-timing-function: ease-out;
  width: 20px;
}

nav .burger.active span:first-child {
  transform: translateY(5px) rotate(45deg);
}
nav .burger.active span:nth-child(2) {
  opacity: 0;
}
nav .burger.active span:nth-child(3) {
  transform: translateY(-5px) rotate(-45deg);
}

nav .menu li .item.button {
  order: 2;
}
nav .menu .menu-item {
  order: 3;
  width: 100%;
  text-align: center;
  display: none;
  justify-content: center;
}
nav .menu .menu-item .item {
  width: 100%;
}
nav .menu .active.menu-item {
  display: flex;
}

/* Desktop menu */
@media all and (min-width: 600px) {
  nav {
    padding: 0 30px;
  }
  nav .menu {
    justify-content: flex-start;
    flex-wrap: nowrap;
    background: none;
  }
  nav .logo {
    margin-right: auto;
    order: 0;
  }

  nav .menu .menu-item {
    order: 1;
    position: relative;
    display: flex;
    width: auto;
  }
  nav .button {
    order: 2;
  }
  nav .toggle {
    display: none;
  }
}
