.home {
  height: 100vh;
  margin-bottom: -62px;
}
.bgimg-1,
.bgimg-2,
.bgimg-3 {
  position: relative;
  #background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bgimg-1 {
  position: relative;
  height: calc(100% - 62px);
}

.bgimg-2 {
  background-image: url("../../img/corn-1.jpg");
  min-height: 400px;
}

.caption {
  position: absolute;
  display: flex;
  top: 40%;
  justify-content: center;
  width: 100%;
  text-align: center;
  z-index: 1;
  pointer-events: none;
}

.caption h1 {
    color: #fff;
    font-size: 6vw;
    font-weight: 500;
    text-shadow: 2px 2px #000;
}

.caption .border {
}

.background-solid {
  width: 100%;
  background-color: #ffffff;
}

.text-break {
  color: #333;
  background-color: white;
  text-align: justify;
  padding: 50px 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
}

.text-break h2 {
  #position: relative;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-size: 20px;
  #f: "Lato", sans-serif;
  color: #111;
}

/* Turn off parallax scrolling for tablets and phones */
@media only screen and (max-device-width: 1024px) {
  .bgimg-1,
  .bgimg-2,
  .bgimg-3 {
    background-attachment: scroll;
  }
  .caption {
  }
  .text-break {
    padding: 50px 25px;
  }
}
