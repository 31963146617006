.postsContainer {
  min-height: calc(100vh - 124px);
  width: 100%;
  background-color: #f2f2f2;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.postsContainer .tableFlex {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 124px);
}

.postsContainer .tableFlex .topRow {
  width: 50%;
  height: 100%;
  flex-direction: column;
  display: flex;
}

.postsContainer table,
.postsContainer th,
.postsContainer td {
  border-style: none;
  border-collapse: collapse;
  text-align: center;
}

.postsContainer .tableContainer .tableRowOdd {
  background: #d4d4d4;
}

.postsContainer .half-table {
  max-height: 50%;
}
.postsContainer .posts-table {
  width: 50%;
}

.postsContainer .tableHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.postsContainer .tableHeading {
  display: block;
  margin: auto;
  align-self: center;
  font-weight: 700;
  font-size: large;
}

.postsContainer table {
  background: white;
  width: 100%;
}

.postsContainer .tableContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /*   overflow-y: scroll; */
}

.postsContainer .tableContent {
  width: 100%;
  overflow-y: scroll;
}

.fbtn {
  display: block;
  color: white;
  font-weight: 500;
  padding: 14px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  text-decoration: none;
}

.okay-btn {
  background-color: #4caf50;
}

.okay-btn:hover {
  background-color: #90ee90;
}

.danger-btn {
  background-color: #ff0000;
}

.danger-btn:hover {
  background-color: #ff5252;
}

.warning-btn {
  background-color: #ff9a00;
}

.warning-btn:hover {
  background-color: #ffc100;
}

.new-btn {
  float: right;
  width: auto;
  padding: 14px 5px;
}

@media screen and (max-width: 720px) {
  .postsContainer .tableFlex {
    height: 100%;
    flex-direction: column;
  }
  .postsContainer .tableContainer {
    max-height: 100%;
  }

  .postsContainer .tableFlex .topRow {
    width: 100%;
  }
  .postsContainer .posts-table {
    width: 100%;
  }
}
