.submitButton {
  padding-top: 16px;
}

.btn {
  width: 100%;
  color: #565656;
  padding: 12px;
  font-size: 14px;
  font-weight: bold;
  border: solid 2px #1189de;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
}
