.bio-container {
  background-color: whitesmoke;
  min-height: calc(100vh - 62px);
  #padding-bottom: 75px;
  position: relative;
  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
}
.bio {
  background: white;
  padding: 25px;
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12),
    0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.12),
    0 8px 8px rgba(0, 0, 0, 0.12), 0 16px 16px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
}

.bio .block {
  position: relative;

  padding-left: 50px;
  padding-top: 10px;
}

.bio .heading {
  #position: absolute;
  #float: left;
  #width: 25%;
  height: 100%;
  #background-color: #8c8bac;
  #color: #ccccff;
  color: black;
  #text-align: center;
  font-size: x-large;
  font-weight: 600;
  /*   letter-spacing: -1.5px; */
  line-height: 25px;
}

.bio .heading h1 {
  #text-align: center;
}

.bio .text {
  #width: 75%;
  overflow: hidden;
  padding-top: 25px;
  padding-bottom: 25px;
  color: black;
  line-height: 12px;
}
.bio .text .element {
  padding-bottom: 5px;
  padding-top: 5px;
}

.bio .text h4 {
  font-size: medium;
  padding-left: 10px;
  font-weight: 600;
  line-height: 20px;
}

.bio .text p {
  margin-top: -15px;
  padding-left: 10px;
  font-size: small;
  font-weight: 300;
  line-height: 12px;
}

.bio .img-container {
  float: right;
  margin: 5px;
  /*   padding-right: 25px; */
  width: 33%;
}

.bio .img-container img {
  height: auto;
  width: 100%;
}

@media screen and (max-width: 820px) {
  .bio {
    padding: 0;
  }
  .bio .text {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-left: 0;
  }

  .bio .text h4 {
    padding-left: 0;
  }

  .bio .text p {
    padding-left: 0;
  }

  .bio {
    width: 100%;
  }
  .bio .img-container {
    float: none;
    margin: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 0 0 0;
    width: 250px;
  }
}
