.post {
  position: relative;
  width: 100%;
  border-style: none;
  transition: all ease 1s;
}

.post-unloaded {
  opacity: 0;
  transform: scale(0.5);
}

.post-loaded {
  transform: scale(1);
  opacity: 1;
}

.post .box {
  background: black;
  display: block;
}

.post .text-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  transition: transform ease 0.3s;
  background-color: rgba(65, 74, 76, 0);
}

.text-container h1 {
  margin: 0;
  font-size: xx-large;
  font-weight: 500;
  color: white;
  text-shadow: 2px 2px #000;
  opacity: 0;
  transition: opacity ease 0.3s;
}

.text-container .price {
  margin: 0;
}

.post .badge {
  /*position: absolute;
  top: 20%;
  margin-left: 5px;*/
  position: relative;
  text-shadow: 0px 0px #000;
  padding: 2px;
  border-radius: 5px;
  background-color: red;
  color: white;
  opacity: 0;
  font-weight: 600;
  transition: opacity ease 0.3s;
  margin-left: auto;
  margin-right: auto;
}

.text-container p {
  color: white;
  text-shadow: 1px 1px #000;
  opacity: 0;
  transition: opacity ease 0.3s;
}

.clicked img {
  opacity: 0.8;
}

.post img {
  position: relative;
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  transition: transform ease 0.3s;
  -moz-box-shadow: 3px 3px 15px rgba(10, 10, 10, 0.6);
  -moz-box-shadow: 3px 3px 15px rgba(10, 10, 10, 0.6);
  box-shadow: 3px 3px 15px rgba(10, 10, 10, 0.6);
}

.no-display {
  display: none;
}

.persistent-badge {
  position: absolute;
  text-align: center;
  font-weight: 600;
  z-index: 1;
  transform: rotate(45deg);
  top: 25px;
  right: -75px;
  width: 200px;
  background: #eb0000;
  color: white;
  transition: opacity ease 0.3s;
}

@media only screen and (min-device-width: 1024px) {
  .post:hover h1,
  .post:hover p,
/*   .post:hover .badge, */
  .post:hover .text-container {
    opacity: 1;
    z-index: 1;
  }

  .post:hover .text-container {
    background-color: rgba(65, 74, 76, 0.4);
  }

  .post:hover .text-container {
    transform: scale(1.05);
  }

  .post:hover .persistent-badge {
    /*     opacity: 0; */
  }
  .post:hover {
    cursor: pointer;
  }

  .post:hover img {
    transform: scale(1.05);
  }
}
