.inputField {
  padding-top: 17px;
}

.input {
  box-sizing: border-box;
  outline: none;
  border: solid 2px #1189de;
  border-radius: 4px;
  color: #292929;
  width: 100%;
  padding: 12px;
  font-size: 14px;
  background: rgba(255, 255, 255, 1);
}
