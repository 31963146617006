.footer {
  background-image: url("../../../img/footer.jpg");
  height: 50px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  display: block;
  z-index: 2;
}
