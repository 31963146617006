.empty-container {
  max-width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.admin-container {
  height: calc(100vh - 124px);
}

.login-container {
  height: calc(100vh - 62px);
}
