.gallery {
  background-color: whitesmoke;
  min-height: 100vh;
  position: relative;
  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.load-container {
  background-color: whitesmoke;
  min-height: 100vh;
  position: relative;
  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.spinner {
  padding-top: 20%;
}

.divider {
  padding-top: 25px;
}

.grid-container {
  display: flex;
  justify-content: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
  margin-left: 50px;
  margin-right: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  transition: all ease 1s;
  align-items: center;
}

@media screen and (max-width: 820px) {
  .grid {
    max-width: 500px;
  }
}
