@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;800&display=swap");
body,
html,
#root,
.App {
  #padding-top: 10px; /* Add a top margin to avoid content overlay */
  height: 100%;
  font-family: "Poppins", sans-serif;
  color: #777;
  background-color: #2f4f4f;
  display: flex;
  flex-direction: column;
}
