adminnav {
  margin: 0;
  padding: 0;
  background: black;
  position: relative;
  z-index: 2;
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12),
    0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.12),
    0 8px 8px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12);
}

adminnav .menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

adminnav .item {
  color: white;
  cursor: pointer;
  transition: background ease 0.3s;
  -webkit-transition: background ease 0.3s;
  padding: 19px 16px;
  font-weight: 500;
}

adminnav .item:first-child {
}

adminnav .item:last-child {
  position: absolute;
  right: 0;
}

adminnav .item:hover {
  background: grey;
}

adminnav a {
  text-decoration: none;
}

adminnav .menu {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
adminnav .menu li a {
  display: block;
  padding: 15px 5px;
}
